<div id="top-of-page"></div>

<aio-cookies-popup></aio-cookies-popup>

<a class="skip-to-content-link" href="#main-content">Skip to main content</a>

<div *ngIf="isFetching" class="progress-bar-container">
  <mat-progress-bar mode="indeterminate" color="warn"></mat-progress-bar>
</div>

<header>
  <mat-toolbar #appToolbar color="primary" class="app-toolbar no-print" [class.transitioning]="isTransitioning">
    <mat-toolbar-row class="notification-container">
      <aio-notification notificationId="war-ukraine" expirationDate="null" [dismissOnContentClick]="false" (dismissed)="notificationDismissed()">
        <a class="link" target="_blank" rel="noopener" href="https://www.google.org/ukraine-relief/">
          <span class="title">Support Ukraine</span>
        <mat-icon class="icon" aria-label="Ukraine flag">
          <svg height="24" width="24" version="1.1" viewBox="0 0 240 240" xmlns="http://www.w3.org/2000/svg">
            <rect fill="#0057b7" height="72" width="240" x="0" y="48"/>
            <rect fill="#ffd700" height="72" width="240" x="0" y="120"/>
          </svg>
        </mat-icon>
          <span class="message">Help Provide Humanitarian Aid to Ukraine.</span>
        </a>
      </aio-notification>
    </mat-toolbar-row>
    <mat-toolbar-row>
      <button mat-button class="hamburger" [class.no-animations]="disableAnimations" (click)="sidenav.toggle()" title="Docs menu">
        <mat-icon svgIcon="menu"></mat-icon>
      </button>
      <a class="nav-link home" href="/" [ngSwitch]="showTopMenu">
        <img *ngSwitchCase="true" src="assets/images/logos/angular/logo-nav@2x.png" width="150" height="40" title="Home" alt="Home">
        <img *ngSwitchDefault src="assets/images/logos/angular/shield-large.svg" width="37" height="40" title="Home" alt="Home">
      </a>
      <aio-top-menu *ngIf="showTopMenu" [nodes]="topMenuNodes" [currentNode]="currentNodes?.TopBar"></aio-top-menu>
      <aio-search-box class="search-container" #searchBox (onSearch)="doSearch($event)" (onFocus)="doSearch($event, true)"></aio-search-box>
      <aio-theme-toggle #themeToggle></aio-theme-toggle>
      <div #externalIcons class="toolbar-external-icons-container">
        <a mat-icon-button href="https://twitter.com/angular" title="Twitter" aria-label="Angular on twitter">
          <mat-icon svgIcon="logos:twitter"></mat-icon>
        </a>
        <a mat-icon-button href="https://github.com/angular/angular" title="GitHub" aria-label="Angular on github">
          <mat-icon svgIcon="logos:github"></mat-icon>
        </a>
        <a mat-icon-button href="https://youtube.com/angular" title="YouTube" aria-label="Angular on YouTube">
          <mat-icon svgIcon="logos:youtube"></mat-icon>
        </a>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</header>

<aio-search-results *ngIf="showSearchResults"
  #searchResultsView
  [searchResults]="searchResults | async"
  (resultSelected)="hideSearchResults()"
  (closeButtonClick)="hideSearchResults()">
</aio-search-results>

<mat-sidenav-container class="sidenav-container" [class.no-animations]="disableAnimations" [class.has-floating-toc]="hasFloatingToc">

  <mat-sidenav [ngClass]="{'collapsed': !dockSideNav}" #sidenav class="sidenav" [mode]="mode" [opened]="isOpened" (openedChange)="updateHostClasses()">
    <aio-nav-menu *ngIf="!showTopMenu" [nodes]="topMenuNarrowNodes" [currentNode]="currentNodes?.TopBarNarrow" [isWide]="dockSideNav" navLabel="primary"></aio-nav-menu>
    <aio-nav-menu [nodes]="sideNavNodes" [currentNode]="currentNodes?.SideNav" [isWide]="dockSideNav" navLabel="guides and docs"></aio-nav-menu>

    <div class="doc-version">
      <aio-select (optionsToggled)="scrollSelectIntoView()" (change)="onDocVersionChange($event.index)" [options]="docVersions" [selected]="currentDocVersion"></aio-select>
    </div>
  </mat-sidenav>

  <section class="sidenav-content-container">

    <main class="sidenav-content" [id]="pageId">
      <div id="main-content" tabindex="-1"></div>
      <aio-mode-banner [mode]="deployment.mode" [version]="versionInfo"></aio-mode-banner>
      <aio-doc-viewer [class.no-animations]="disableAnimations" [doc]="currentDocument" (docReady)="onDocReady()" (docRemoved)="onDocRemoved()" (docInserted)="onDocInserted()" (docRendered)="onDocRendered()">
      </aio-doc-viewer>
      <aio-dt *ngIf="dtOn" [(doc)]="currentDocument"></aio-dt>
    </main>

    <div *ngIf="hasFloatingToc" class="toc-container no-print" [style.max-height.px]="tocMaxHeight" (wheel)="restrainScrolling($event)">
      <aio-lazy-ce selector="aio-toc"></aio-lazy-ce>
    </div>

  </section>

  <footer class="no-print">
    <aio-footer [nodes]="footerNodes" [versionInfo]="versionInfo"></aio-footer>
  </footer>

</mat-sidenav-container>

